.o-burger {
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;

  &__icon {
    @include burger(
      map-get($burger, width),
      map-get($burger, height),
      map-get($burger, gutter),
      map-get($burger, colour),
      map-get($burger, borderRadius),
      map-get($burger, transitionDuration)
    );
  }

  &.is-active .o-burger__icon {
    @include burger-to-cross;
  }
}

.o-burger {
  justify-self: flex-end;
  grid-area: burger;
}
