/**
  *   #COLOURS
  */

:root {
  --colourBase: #000000;
  --colourDark: #000000;
  --colourDark-rgb: 0, 0, 0;
  --colourLight: #ffffff;
  --colourLight-rgb: 255, 255, 255;
  --colourBackground: #ffffff;
  --colourContent: #000000;
  --colourDisabled: #999999;
  --colourLink: #000000;
  --colourHover: #000000;
  --colourActive: #000000;
  --colourVisited: #000000;
  --colourErrorBackground: #ff7474;
  --colourErrorText: #bb0000;
  --colourFooter: #fafafa;
}
