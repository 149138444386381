.o-list-bare {
  list-style: none;
  margin-left: 0;
}

.o-list-bare__item {
  margin-left: 0;
}

.o-nav {
  display: none;
  justify-content: center;
  margin: 0;
  grid-area: menu;

  @include mq($from: tablet) {
    display: flex;
  }

  &__link {
    margin: var(--spacingBase);
    text-align: center;
    letter-spacing: em(.35);
    display: block;
    position: relative;

    &::after {
      @include pseudoElement;

      bottom: 0;
      width: 100%;
      left: 0;
      margin: 0;
      height: 1px;
      background: var(--colourDark);
      transform: scaleX(0);
      transition: colour .4s ease,
        transform .4s cubic-bezier(.19, 1, .22, 1);
      transform-origin: left center;
    }

    &:hover,
    &.is-active {
      &::after {
        transform: scale(1);
        transform-origin: right center;
      }
    }
  }
}
