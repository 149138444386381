.o-header {
  background: rgba(var(--colourLight-rgb), .9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: depth(site, aboveAll) + 1;

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'brand burger';
    grid-template-rows: auto;
    min-height: em(60);
    align-items: center;

    @include mq($from: tabletPortrait) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas: 'brand menu menu burger';
    }
  }
}
