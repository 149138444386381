@include mq($from: mobile) {
  :root {
    --fontSizeLarge: #{em(48, $type: rem)};
    --fontSizeXlarge: 2.4vw;
  }
}
@include mq($from: tablet) {
  :root {
    --fontSizeXlarge: #{em(40, $type: rem)};
    --fontSizeXXXlarge: #{em(36, $type: rem)};
  }
}
.o-font {
  &__size {
    &--smallest {
      font-size: var(--fontSizeSmallest);
      line-height: 1.3;
    }

    &--small {
      font-size: var(--fontSizeSmall);
      line-height: 1.3;
    }

    &--normal {
      font-size: var(--fontSizeNormal);
      line-height: 1.3;
    }

    &--medium {
      font-size: var(--fontSizeMedium);
      line-height: 1.3;
    }

    &--large {
      font-size: var(--fontSizeLarge);
      line-height: 1.3;
    }

    &--xlarge {
      font-size: var(--fontSizeXlarge);
      line-height: 1.3;
    }

    &--xxlarge {
      font-size: var(--fontSizeXXlarge);
      line-height: 1.3;
    }

    &--xxxlarge {
      font-size: var(--fontSizeXXXlarge);
      line-height: 1.3;
    }
  }

  &__transform {
    &--upper {
      text-transform: uppercase;
    }
  }

  &__colour {
    &--light {
      color: var(--colourLight);
    }
    &--darkP {
      color: var(--colourDark);
    }
  }

  &__weight {
    &--thin {
      font-weight: 100;
    }

    &--light {
      font-weight: 300;
    }

    &--regular {
      font-weight: 400;
    }

    &--medium {
      font-weight: 500;
    }

    &--bold {
      font-weight: 700;
    }

    &--black {
      font-weight: 900;
    }
  }

  &__align {
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }

  &__margin {
    &--half {
      margin-bottom: var(--spacingHalf);
    }

    &--one {
      margin-bottom: var(--spacingHalf);
    }
    &--double {
      margin-bottom: var(--spacingDouble);
    }
    &--zero {
      margin-bottom: 0;
    }
  }

  &__family {
    &--sans {
      font-family: $sansSerif;
    }
    &--serif {
      font-family: $serif;
    }
  }
}
