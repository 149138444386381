.modal {
  display: none;

  .modal-close {
    position: fixed;
    right: 2em;
    top: 2em;
    width: 40px;
    height: 40px;
    background: var(--colourDark);
    cursor: pointer;
    z-index: depth(site, aboveAll) + 1;

    &::before,
    &::after {
      @include pseudoElement;
      height: 25px;
      width: 4px;
      background-color: var(--colourLight);
      z-index: 1;
      left: 50%;
      top: 50%;
      border-radius: 2px;
      margin: -12px 0 0 -2px;
      cursor: pointer;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  }

  .modal-bg {
    background: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: depth(site, aboveAll);
  }

  .modal-inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: depth(site, aboveAll);
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .content-container {
    position: relative;
    z-index: depth(site, aboveAll);
    width: 80vw;
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 0 25px -5px rgba(0, 0, 0, .7);
    display: flex;
    flex-direction: column;

    @include mq($from: tabletPortrait) {
      flex-direction: row;
    }
  }

  .left {
    overflow: hidden;
    width: 50%;

    .image-desktop {
      display: block;
      height: 100%;
    }

    .image-mobile {
      display: none;
    }

    @include mq($until: tabletPortrait) {
      width: 100%;

      .image-desktop {
        display: none;
      }

      .image-mobile {
        display: block;

        img {
          height: auto;
          max-height: 45vh;
          max-width: 100vmin;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 90vmin;
      display: block;
      object-fit: cover;
      object-position: center center;
    }
  }

  .right {
    padding: 2em;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--colourLight);

    @include mq($until: tabletPortrait) {
      width: 100%;
    }

    .logo {
      max-width: 100px;
    }
  }

  iframe {
    width: 100%;
    height: 90vh;
  }
  .text {
    text-align: center;
    margin: var(--spacingDouble) 0;
  }

  .cta a,
  .newsletter-dismiss {
    @include link-colors(var(--colourDark), var(--colourLight));

    padding: var(--spacingHalf) var(--spacingDouble);
    border: solid 2px var(--colourDark);
    width: 150px;
    display: flex;
    text-align: center;
    justify-content: center;
    transition: color .3s ease, background .3s ease;
    margin-bottom: var(--spacingDouble);

    &:hover {
      background-color: var(--colourDark);
    }
  }
}
