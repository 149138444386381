// SETTINGS
@import 'tools/tools.getEM';

@import 'settings/settings.colours';
@import 'settings/settings.global';
@import 'settings/settings.fonts';
@import 'settings/settings.responsive';

@import '~@agilepixel/poise';

// UTILITIES
/*
* u-: Signify that this class is a Utility class. It has a very specific role (often providing only one declaration) and should not be bound onto or changed. It can be reused and is not tied to any specific piece of UI.
*/

// @import 'utilities/utilities.example';

// OBJECTS
/*
 * o-: Signify that something is an Object, and that it may be used in any number of unrelated contexts to the one you can currently see it in. Making modifications to these types of class could potentially have knock-on effects in a lot of other unrelated places. Tread carefully.
 */

@import 'objects/objects.nav';
@import 'objects/objects.alert';
@import 'objects/objects.aside';
@import 'objects/objects.brand';
@import 'objects/objects.burger';
@import 'objects/objects.content';
@import 'objects/objects.footer';
@import 'objects/objects.header';
@import 'objects/objects.main';
@import 'objects/objects.meta';
@import 'objects/objects.summary';
@import 'objects/objects.title';
@import 'objects/objects.fonts';
@import 'objects/objects.modal';

// COMPONENTS
/*
 * c-: Signify that something is a Component. This is a concrete, implementation-specific piece of UI. All of the changes you make to its styles should be detectable in the context you’re currently looking at. Modifying these styles should be safe and have no side effects.
 */

@import 'components/components.buttons';
@import 'components/components.comments';
@import 'components/components.nav';
@import 'components/components.pager';
@import 'components/components.section';
@import 'components/components.hero';

//Sony Components
@import 'sony/sony.modal';
@import 'sony/sony.release';
@import 'sony/sony.video';
@import 'sony/sony.video-overlay';

/**
  *   #SKIP TO
  */

.c-skipto {
  position: absolute;
  width: 100%;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  display: block;

  &:focus,
  &:active {
    position: static;
    clip: unset;
    -webkit-clip-path: none;
    clip-path: none;
    background: var(--colourLight);
    padding: var(--spacingBase);
    display: block;
  }

  &:hover {
    text-decoration: underline;
    color: var(--colourDark);
  }
}
