.o-footer {
  background: var(--colourFooter);

  &__layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include mq($from: mobile) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__inner {
    padding: var(--spacingBase) 0;
  }

  &__copyright {
    margin: 0 0 var(--spacingDouble) 0;

    @include mq($from: mobile) {
      margin: 0 var(--spacingDouble) 0 0;
    }
  }

  &__nav {
    display: flex;

    &__link {
      position: relative;

      &::before,
      &::after {
        @include pseudoElement;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--colourDark);
        transform: scaleX(0);
        transition: colour .4s ease,
          transform .4s cubic-bezier(.19, 1, .22, 1);
      }

      &::before {
        top: 0;
        transform-origin: right center;
      }

      &::after {
        bottom: 0;
        transform-origin: left center;
      }

      &:hover {
        &::after,
        &::before {
          transform: scaleX(1);
        }
        &::before {
          transform-origin: left center;
        }
        &::after {
          transform-origin: right center;
        }
      }
    }
    &__item {
      padding: 0 var(--spacingBase);
      position: relative;

      &::after {
        @include pseudoElement;

        width: em(2);
        height: em(15);
        background: var(--colourDark);
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  &__sony {
    flex-grow: 1;
    width: 100%;
    text-align: center;
  }
}
