.o-page {
  &__section {
    min-height: calc(70vh);
    &--about {
      max-width: 800px;
    }
  }

  &--center {
    margin: 5vh auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__inner {
    margin: 2em 0;
  }
}
