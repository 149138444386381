@include mq($from: tabletPortrait) {
  :root {
    --spacingGrid: #{em(60)};
    --spacingGridNegative: -#{em(60)};
  }
}

.c-releases {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1020px;
  margin: 10vh auto 0;

  @include mq($from: tabletPortrait) {
    margin-top: 20vh;
  }

  .release {
    opacity: 0;
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: calc(var(--spacingGrid) * 2);

    @include mq($from: tabletPortrait) {
      max-width: calc(50% - var(--spacingGrid));
      flex-basis: calc(50% - var(--spacingGrid));
    }

    @include mq($from: tablet) {
      max-width: calc(33.33333334% - var(--spacingGrid));
      flex-basis: calc(33.33333334% - var(--spacingGrid));
    }
  }
}

.release-date,
.release-custominfo,
.release-embed,
.tracklist,
.release-title {
  display: none;
}

.packshot {
  margin-bottom: var(--spacingTwo);
}
.retail-links {
  .cta {
    @extend .c-button;
    @include link-colors(var(--colourDark), var(--colourLight));

    text-transform: uppercase;
    margin: 0 auto;
    font-family: $serif;
    border-color: var(--colourDark);

    &::after {
      background: var(--colourDark);
    }
  }
}
