///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.

$inputBorder: 1px solid var(--colourDark);

/**
  *   #DEFAULT VARIABLES
  */
$wrapper: (
  maxWidth: 1320px,
  width: 95%
);

$burger: (
  width: 30px,
  height: 4px,
  gutter: 4px,
  colour: var(--colourDark),
  borderRadius: 0,
  transitionDuration: .3s
);

/**
  *   #RATIOS
  */

$inuit-ratios: (
  '16\\:9': (
    16: 9
  ),
  '2:\\1': (
    2: 1
  )
);

/**
  *   #SPACING
  */

:root {
  --spacingBase: #{em(12)};
  --spacingOne: #{em(16)};
  --spacingHalf: #{em(6)};
  --spacingTwo: #{em(32)};
  --spacingDouble: #{em(24)};
  --spacingTriple: #{em(36)};
  --spacingQuad: #{em(48)};
  --spacingGrid: #{em(30)};
  --spacingGridNegative: -#{em(30)};
  --spacingBaseNegative: -#{em(28)};
}
/**
  *   #DEPTH
  */

@function depth($depth, $default: 'base') {
  @return map-get(map-get($depths, $depth), $default);
}

$depths: (
  site: (
    base: 1,
    behind: -1,
    zero: 0,
    above: 10,
    high: 15,
    higher: 20,
    highest: 100,
    aboveAll: 150
  )
);

/**
  *   #INPUT TYPES
  */

$inputTypes: 'color', 'date', 'datetime', 'datetime-local', 'email', 'file',
  'hidden', 'image', 'month', 'number', 'password', 'range', 'search', 'tel',
  'text', 'time', 'url', 'week';
