.o-aside {
  position: fixed;
  overflow: auto;
  background-color: rgba(var(--colourLight-rgb), .9);
  box-shadow: 0 0 15px -10px rgba(var(--colourDark-rgb), .4);
  transform: translateX(100%);
  transition: transform .3s ease;
  height: 100%;
  max-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: depth(site, aboveAll);

  &.is-active {
    transform: translateX(0);
  }

  &__nav {
    margin: 0;

    &__link {
      letter-spacing: em(.35);
      margin-bottom: em(30, $type: rem);

      @include mq($from: tabletPortrait) {
        margin-bottom: em(60, $type: rem);
      }
    }
  }
}
