#video-player {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 800px;
  min-width: 300px;
  transform: translate(-50%, -50%);
  z-index: depth(site, aboveAll);
  visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
  display: none;

  &.active {
    display: block;

    visibility: visible;

    #video-player-inner {
      opacity: 1;
    }

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &::after {
    @include pseudoElement;

    background-color: rgba(var(--colourLight-rgb), .9);
    position: fixed;
    width: 1000vh;
    height: 1000vh;
    visibility: hidden;
    top: -100vh;
    left: -100vh;
    z-index: depth(site);
    opacity: 0;
    transition: opacity .3s ease;
  }
}
#video-player-inner {
  @extend .o-modal__content;
  @extend .o-ratio !optional;
  @extend .o-ratio--16\:9 !optional;

  width: 100%;
  z-index: depth(site, above);
}

#video-player-close {
  @extend .o-modal__close;

  cursor: pointer;
}
