.c-hero {
  position: relative;
  z-index: depth(site);
  overflow: hidden;

  &__inner {
    height: 100vh;
    padding: em(30, $type: rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    z-index: depth(site);

    @include mq($from: tabletPortrait) {
      padding: em(60, $type: rem);
    }

    @include mq($from: tabletPortrait) {
      &--left {
        align-items: flex-start;

        .c-hero__content {
          align-items: flex-start;
          text-align: left;
          margin: 0;
        }
      }

      &--right {
        align-items: flex-end;

        .c-hero__content {
          align-items: flex-end;
          text-align: right;
          margin: 0;
        }
      }

      &--bottom {
        justify-content: flex-end;
      }

      &--top {
        justify-content: flex-start;
      }
    }
  }

  &__poster {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: depth(site);
  }

  &__title {
    letter-spacing: .5px;
    margin-bottom: em(30, $type: rem);
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
}
