.o-modal {
  &__overlay {
    background: rgba(var(--colourLight-rgb), .9);
  }

  &__inner {
    position: relative;
  }

  &__content {
    padding: 0;
    box-shadow: 0 10px 25px -10px var(--colourDark);
  }

  &__close {
    position: absolute;
    right: 0;
    top: var(--spacingBaseNegative);
    background: transparent;
    border: 0;
    appearance: none;
    width: 30px;
    height: 30px;
    z-index: depth(site, above);
    cursor: pointer;

    @include mq($from: tabletPortrait) {
      right: var(--spacingBaseNegative);
    }

    &::before,
    &::after {
      @include pseudoElement;

      top: 35%;
      left: 0;
      height: 4px;
      width: 30px;
      background: var(--colourDark);
    }

    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}
