.c-videos {
  margin-top: em(60);

  @include mq($from: tablet) {
    display: flex;
  }

  .video {
    flex-grow: 1;
    position: relative;
    transition: width 1s ease;
    margin-bottom: var(--spacingBase);

    @include mq($from: tablet) {
      margin: 0;
      transform: skew(-12deg, 0);
      width: 20%;
      height: calc(100vh - #{em(60)});

      &:hover {
        width: 30vw;

        .video-subtitle h3 {
          color: var(--colourLight);
          font-size: 200%;
        }
      }
    }
  }

  .video-thumb {
    @include mq($from: tablet) {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  .video-subtitle {
    @include mq($until: tablet) {
      position: absolute;
      top: var(--spacingBase);
      left: var(--spacingBase);
    }

    h3 {
      font-size: var(--fontSizeMedium);
      color: var(--colourLight);
      letter-spacing: 1.5px;
      font-family: $serif;
      padding: var(--spacingBase);
      z-index: depth(site, above);
      position: relative;
      transition: color .3s ease, font-size .3s ease;

      @include mq($from: tablet) {
        transform: skew(12deg, 0);
        color: rgba(var(--colourLight-rgb), .6);
      }
    }
  }

  .video-link {
    display: block;
    position: relative;
    z-index: depth(site);

    @include mq($from: tablet) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    &::after {
      @include pseudoElement;

      position: absolute;
      top: 50%;
      left: 50%;
      background-image: url('../../images/play.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 72px;
      height: 81px;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.c-youtube {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}