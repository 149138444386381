.o-brand {
  grid-area: brand;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  flex: 0 0 em(145);
  letter-spacing: em(.35);

  svg {
    margin-right: em(12);
  }
}
