/**
  *   #BUTTONS
  */

.c-button {
  @include link-colors(var(--colourLight), var(--colourDark));

  width: em(180, $type: rem);
  border: 2px solid var(--colourLight);
  padding: 0 var(--spacingDouble);
  height: em(60, $type: rem);
  display: flex;
  appearance: none;
  margin: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  text-align: center;
  position: relative;
  transition: color .3s ease;

  span {
    position: relative;
    z-index: depth(site, above);
  }

  &::after {
    @include pseudoElement;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--colourLight);
    transform: scaleX(0);
    transition: colour .4s ease, transform .4s cubic-bezier(.19, 1, .22, 1);
    transform-origin: right center;
    z-index: depth(site, behind);
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: left center;
    }
  }
  /**
  * Fix a Firefox bug whereby `input type='submit'` gains 2px extra padding.
  */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
